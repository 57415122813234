.card--flipcard .card__wrapper {
  perspective: 800px;
}
.card--flipcard .card__wrapper .card__front,
.card--flipcard .card__wrapper .card__back,
.card--flipcard .card__wrapper .card__header {
  @apply absolute bg-[white] transition-all duration-[0.4s] ease-[ease-in-out] z-[100];
  backface-visibility: hidden;
  transform-style: preserve-3d;
}
.card--flipcard .card__wrapper .card__front {
  @apply z-10;
  transform: rotateX(0deg) rotateY(0deg);
}
.card--flipcard .card__wrapper .card__back {
  @apply z-[5];
  transform: rotateY(-179deg);
}
.card--flipcard .flip .card__front {
  @apply z-[5];
  transform: rotateY(179deg);
}
.card--flipcard .flip .card__back {
  @apply z-10;
  transform: rotateX(0deg) rotateY(0deg);
}
.card .card__front {
  @apply z-10;
  transform: rotateX(0deg) rotateY(0deg);
}
.card .card__back {
  @apply z-[5];
  transform: rotateY(-179deg);
}
.card-bm--low {
  @apply text-[#457A1D];
}
.card-bm--medium {
  @apply text-[#966D1D];
}
.card-bm--high {
  @apply text-[#AB150B];
}
