#tracker {
  ul[role=tablist] > li.active {
    @apply bg-primary text-secondary
  }

  .tab-content > .tab-pane {
    display: none;
  }

  .tab-content > .active {
    display: block
  }

  .tracker-form.grid .text-danger-default.js-ajax-msg {
    order: 1;
    margin-left: auto;
  }
}
