.card {
  @apply flex flex-col flex-1 rounded-lg border border-mid-gray;

  &.card-horizontal {
    @apply flex-row xs:flex-col [&>.card-image]:max-w-24 [&>.card-image]:xs:max-w-full;
    @apply [&>.card-image]:rounded-lg [&>.card-image]:rounded-r-none;
    @apply [&>.card-image]:xs:rounded-lg [&>.card-image]:xs:rounded-b-none;
    @apply [&>.card-body]:rounded-r-lg [&>.card-body]:xs:rounded-t-none;
  }
}

.card-image {
  @apply bg-mid-gray h-52 object-cover rounded-t-lg;

  &.fixed-width {
    @apply xs:min-w-[327px];
  }
}

.card-body {
  @apply flex flex-col flex-1 p-4 rounded-lg bg-white rounded-t-none min-h-28;
  @apply [&:has(.card-footer)]:min-h-36 [&:has(.card-header):has(.card-footer)]:min-h-48;
}

.card-title {
  @apply my-auto items-center text-title text-center line-clamp-2;
}

.card-footer {
  @apply mt-3 flex gap-3 items-center justify-between;
}