.form-step-wizard {
  @apply mb-8 md:mb-16 flex justify-center;
}
.step-wizard-step {
  @apply w-3/12 relative z-1 mb-2;
}
.step-wizard-step::before {
  @apply content-[''] block w-6/12 h-2.5 absolute -translate-y-2/4 z-[-1] top-2/4 right-0 bg-mid-gray;
}
.step-wizard-step:last-child::before {
  @apply hidden;
}
.step-wizard-step::after {
  @apply content-[''] block w-6/12 h-2.5 absolute -translate-y-2/4 z-[-1] top-2/4 left-0 first:hidden bg-primary;
}
.disabled.step-wizard-step::before,
.disabled.step-wizard-step::after {
  @apply bg-mid-gray;
}
.active.step-wizard-step::before {
  @apply bg-mid-gray;
}
.active.step-wizard-step::after {
  @apply bg-primary;
}
.complete.step-wizard-step::before,
.complete.step-wizard-step::after {
  @apply bg-primary;
}
.step-wizard-dot {
  @apply relative md:w-10 md:h-10 w-7 h-7 mx-auto rounded-full flex items-center justify-center bg-mid-gray;
}
.disabled.step-wizard-step .step-wizard-dot {
  @apply bg-mid-gray;
}
.active.step-wizard-step .step-wizard-dot,
.complete.step-wizard-step .step-wizard-dot {
  @apply bg-primary;
}
.active.step-wizard-step .step-wizard-dot::after {
  @apply content-[""] block absolute -translate-y-2/4 z-[-1] top-2/4 left-full w-full h-2.5 bg-primary rounded-r-full -ml-1;
}

.form-step-wizard > .step-wizard-step:last-child .step-wizard-dot::after {
  @apply hidden;
}
