@import 'flatpickr/dist/themes/light.css';
@import 'remodal/dist/remodal.css';
@import 'remodal/dist/remodal-default-theme.css';
@import 'select2/dist/css/select2.css';
@import './components/navigate-select2.css';
@import './components/alerts.css';
@import './components/biometrics.css';
@import './components/buttons.css';
@import './components/card.css';
@import './components/checkboxes.css';
@import './components/coaching.css';
@import './components/inputs.css';
@import './components/owl.css';
@import './components/radios.css';
@import './components/socialWall.css';
@import './components/tooltips.css';
@import './components/tracker.css';
@import './components/rbc-calendar.css';
@import './components/step-wizard.css';
@import './components/my-grocery-deals-service.css';
@import './components/forms.css';
@import './components/pulse-survey-notifications.css';

/* == == */
.wrapper {
  @apply p-4 shadow-container rounded-lg bg-white mb-5;
}

.profile-pic {
  @apply overflow-hidden inline-block align-middle w-8 h-8 bg-no-repeat bg-center bg-cover bg-mid-dark-gray rounded-full;
}

a.link {
  @apply text-primary;
}

a.link:hover {
  @apply underline;
}

.tag {
  @apply bg-primary/30 px-2 py-1 rounded-lg text-primary text-accent;
}

.square-8 { @apply w-8 h-8; }
.square-11 { @apply w-11 h-11; }
.square-14 { @apply w-14 h-14; }
.square-16 { @apply w-16 h-16; }
.square-20 { @apply w-20 h-20; }
.square-28 { @apply w-28 h-28; }
.square-44 { @apply w-44 h-44; }

svg.pie circle {
  @apply transition-[stroke-dashoffset] duration-[1s] ease-linear stroke-[1em] ;
}
svg.pie circle.empty {
  @apply stroke-light-gray stroke-[1.1em];
}

.btn-with-selection-arrow {
  @apply relative inline-flex items-center justify-between;
}
.btn-with-selection-arrow::after {
  @apply content-[''] block border-transparent border-b-mid-dark-gray border-r-mid-dark-gray border-t-[1px]  border-l-[1px]  border-r-[2px]  border-b-2 size-[6px] border-solid;
  transform: translate(-50%, -25%) rotate(45deg);
}
.btn-with-selection-arrow::after:hover {
  @apply border-b-dark-gray border-r-dark-gray;
}
.active.btn-with-selection-arrow::after {
  @apply border-b-black border-r-black;
}

.remodal {
  @apply rounded-lg;
}

details {
  @apply border rounded-lg border-mid-light-gray;
  display: flex;
  flex-direction: column;

  fieldset {
    legend {
      @apply text-xl font-normal mb-3;
    }
  }

  .content {
    @apply p-4;
  }
}

details summary {
  @apply p-3;
  background-color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  list-style: none;
}

details summary::marker {
  display: none;
}

details[open] summary::after {
  @apply content-carat-up;
  margin-left: auto;
}

details summary::after {
  @apply content-carat-down;
  margin-left: auto;
}
