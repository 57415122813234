.mgd-location .mgd-deal-count, .mgd-location .mgd-location-none {
  @apply text-lg font-bold leading-150;
}

.mgd-location .mgd-location-form.active {
    height: auto;
    margin-bottom: 10px;
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    z-index: 1000;
}

.mgd-location .mgd-location-form {
    margin-top: 10px;
    height: 0;
    overflow: visible;
    opacity: 0;
    position: relative;
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transition: all 0.2s ease;
    z-index: 1;
}

.mgd-form-control {
  @apply !block w-full mb-6 border rounded-4px border-mid-gray bg-white !px-2 !py-2.5 !text-base leading-150 text-black placeholder:text-opacity-75 active:border-black focus:border-black focus:outline-0 focus:shadow-none outline-0 disabled:bg-mid-light-gray disabled:text-dark-gray;
}
.mgd-form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}
.mgd-form-control.mgd-form-control-zip {
  @apply !inline-block w-2/3;
}

.mgd-form-label,
.mgd-location .mgd-location-form label {
  @apply block text-form-label text-black mb-1 first-letter:uppercase;
}

.mgd-form-inline .SumoSelect {
    margin-right: 5px;
}

.mgd-form-label {}

.mgd-location .mgd-btn.mgd-btn-primary.mgd-btn-set-location.mgd-bg-clr-primary {
  @apply inline-block w-1/3 mt-1 py-9px px-18px rounded-full border border-transparent cursor-pointer text-nowrap text-center text-secondary bg-primary border-primary;
}

.mgd-location-current {
  @apply text-base leading-150;
}

.mgd-bg-clr-primary {
  @apply bg-primary;
}

.mgd-btn-as-link.mgd-btn-change-location.mgd-clr-primary {
  @apply text-black m-0 p-0 cursor-pointer bg-none border-none no-underline;
}
.mgd-btn-as-link.mgd-btn-change-location.mgd-clr-primary:hover {
  opacity: 1;
  text-decoration: underline;
}

.mgd-btn-primary, .mgd-btn-icon {
  color: #fff;
  opacity: 0.8;
}
.mgd-btn {
    display: inline-block;
    padding: 0.25em 0.5em;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.mgd-location div.SumoSelect {
  font-size: 1rem;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  outline: none;
  width: 100%;
}

.mgd-location .SumoSelect > .CaptionCont {
  @apply !block w-full border rounded-4px border-mid-gray bg-white !px-2 !py-2.5 !text-base leading-150 text-black placeholder:text-opacity-75 active:border-black focus:border-black focus:outline-0 focus:shadow-none outline-0 disabled:bg-mid-light-gray disabled:text-dark-gray;
}

.mgd-location .SumoSelect > .optWrapper.open {
  top: auto;
}

.SumoSelect > .optWrapper > .options > li label, .SumoSelect > .CaptionCont {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}
.SumoSelect p {
    margin: 0;
}
.SlectBox {
    z-index: 900;
}
.SlectBox {
    width: 200px;
    padding: 5px 8px;
}
.mgd-location .SumoSelect .selected i {
  @apply !bg-primary;
}

.mgd-location .SumoSelect .select-all.partial i {
  @apply !bg-primary opacity-45;
}

.mgd-btn-ingredient {
  @apply inline-block;
}
.mgd-icon {
  @apply transform-none;
}
