.pulse-notification-wrapper .pulse-notification .pulse-notification-content {
  @apply max-w-490px !m-0;
}
.pulse-notification-wrapper .pulse-notification .pulse-notification-content .form-fieldset {
  @apply m-0;
}
.pulse-notification-wrapper .pulse-notification .pulse-actions {
  @apply !hidden;
}
.pulse-notification-wrapper .pulse-notification .form-actions {
  @apply pb-0;
}

.pulse-toast--wrapper {
  @apply !overflow-visible;
}
.pulse-toast--wrapper .pulse-toast {
  @apply !bg-transparent !shadow-none !p-0 !overflow-visible;
}
