.form-checkbox {
  @apply relative;
}
.form-checkbox.checkbox {
  @apply w-full;
}
.form-checkbox .form-checkbox__input {
  @apply absolute opacity-0;
}
.form-checkbox__label {
  @apply cursor-pointer text-black flex gap-2 relative;
}

.form-checkbox.checkbox .form-checkbox__label::before {
  @apply content-[''] flex justify-center items-center w-6 h-6 rounded-lg border-2 border-solid border-black leading-[0] flex-shrink-0;
}
.form-checkbox.checkbox .form-checkbox__label.is-invalid::before {
  @apply border-danger-default;
}
.form-checkbox.checkbox.checked + .form-checkbox__label::before,
.form-checkbox.checkbox .form-checkbox__input:checked + .form-checkbox__label::before {
  @apply content-check-mark;
}

.form-checkbox.checkbox .form-checkbox__label.disabled::before {
  @apply border-mid-gray;
}

.form-checkbox.checkbox .form-checkbox__input:checked + .form-checkbox__label.disabled::before {
  @apply content-check-mark-disabled border-mid-gray;
}

.form-checkbox.checkbox-after .form-checkbox__label::after {
  @apply content-[''] absolute right-0 xs:right-8 md:right-52 flex justify-center items-center w-6 h-6 rounded-lg border-2 border-solid border-black;
}
.form-checkbox.checkbox-after .form-checkbox__label.is-invalid::after {
  @apply border-danger-default;
}
.form-checkbox.checkbox-after .form-checkbox__input:checked + .form-checkbox__label::after {
  @apply content-check-mark;
}

.form-checkbox.checkbox-after .form-checkbox__label.disabled::after {
  @apply border-mid-gray;
}

.form-checkbox.checkbox-after .form-checkbox__input:checked + .form-checkbox__label.disabled::after {
  @apply content-check-mark-disabled border-mid-gray;
}


.form-checkbox.graphic-checkbox {
  @apply relative;
}
.form-checkbox.graphic-checkbox .form-checkbox__label {
  @apply border border-mid-gray p-3 pb-6 rounded-lg transform transition duration-300 flex-col items-center w-32;
}
.form-checkbox.graphic-checkbox .form-checkbox__label:not(.disabled):hover {
  @apply  shadow-md border-black;
}
.form-checkbox.graphic-checkbox .form-checkbox__label.is-invalid {
  @apply border-danger-default;
}
.form-checkbox.graphic-checkbox .form-checkbox__input:checked + .form-checkbox__label {
  @apply shadow-md border-black;
}
.form-checkbox.graphic-checkbox .graphic-checkbox__image {
  @apply mb-3 bg-center bg-no-repeat bg-cover max-w-14;
}

.form-checkbox .form-checkbox__label.disabled {
  @apply cursor-default;
}
