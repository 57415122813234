div#zoom-container
  div#participantsContainer
  #localParticipant
  .floating_local_video {
  @apply absolute max-w-[125px] md:max-w-[250px] right-0 bottom-0;
}
div#zoom-container
  div#participantsContainer
  #localParticipant
  .floating_local_screen_share {
  @apply absolute max-w-[250px] max-h-60 left-0 bottom-0;
}
div#zoom-container
  div#participantsContainer
  #remoteParticipants
  #screen-share-holder {
  @apply max-w-full h-auto h-[481.789px] mx-auto;
}
div#zoom-container
  div#participantsContainer
  #remoteParticipants
  .floating_remote_video {
  @apply absolute max-w-[250px] max-h-60 left-0 bottom-0;
}
div#zoom-container video,
div#zoom-container canvas {
  @apply max-w-full h-auto max-h-[481.789px] mx-auto;
}
#session .resource {
  @apply w-[200px] h-[200px] border rounded-[10px] border-solid border-[#ccc];
}
#session .resource img {
  @apply h-full w-full object-contain p-[15px];
}
