#wall-container {
  @apply w-full min-h-[500px] relative m-auto;
}
#wall-container .btn:focus {
  @apply shadow-none;
}
#settings-container {
  @apply sticky transition-none top-[75px] md:pl-0 md:pr-2 md:py-0;
}

#settings-container #settings button.border-0:focus {
  @apply shadow-none;
  outline: none;
}
#settings-container .btn-outline-danger:hover {
  @apply text-[#CB1D3E] bg-transparent;
}
#settings-container .group-list {
  @apply max-h-[300px] overflow-y-auto;
}
@media (max-width: 768px) {
  #settings-container {
    @apply pt-0 pb-[5px] px-0;
  }
}
#feed-container {
  @apply transition-none md:pl-2 md:pr-0 md:py-0;
}
@media (max-width: 768px) {
  #feed-container {
    @apply pt-[5px] pb-0 px-0;
  }
}
.social-post .btn:focus {
  @apply shadow-none;
}
.post-body-image {
  @apply w-full h-[20vw];
}
.post-body-image img {
  @apply h-full w-full object-contain;
}
.social-comment {
  @apply min-w-[250px];
}
.social-comment-pic {
  @apply min-w-[30px];
}
.post-form textarea {
  @apply resize-none;
}
.post-form label[for^="post-image"] {
  @apply p-1.5;
}
.post-form input[id^="remove-image"] {
  @apply mb-0.5;
}
.post-form .emoji-picker .emoji-mart-search-icon {
  @apply top-[3px];
}
.post-form .emoji-button {
  @apply pt-1 pb-0.5 px-2;
}
.post-form .fa-smile-o {
  @apply text-2xl;
}
.activity-container .emoji-picker .emoji-mart-search-icon {
  @apply top-[3px];
}
.activity-container textarea {
  @apply resize-none;
}
.activity-container button:hover {
  @apply no-underline;
}
.activity-container button:focus {
  @apply no-underline;
}
.social-selectors {
  @apply min-w-[175px];
}
.social-selectors select {
  @apply leading-4 h-auto;
}
.kudos-award {
  @apply w-[50px] max-h-[75px] fill-[#3599BA] object-cover;
}

/* This is necessary so that user search
dropdowns don't fall behind the footer */
#social_wall-index .row-offcanvas {
  @apply z-[3] relative;
}
#social_wall-show .row-offcanvas {
  @apply z-[3] relative;
}
