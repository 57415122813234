.form-radio.radio {
  @apply relative w-full;
}
.form-radio .form-radio__input {
  @apply absolute opacity-0;
}
.form-radio .form-radio__label {
  @apply relative cursor-pointer text-black flex gap-2.5 leading-none items-center;
}
.form-radio.radio .form-radio__label::before {
  @apply content-[''] w-6 h-6 block rounded-full border-2 border-solid border-black;
  flex: 1 0 auto;
}
.form-radio.radio .form-radio__label.is-invalid::before {
  @apply border-danger-default;
}
.form-radio.radio .form-radio__label.disabled::before {
  @apply border-mid-gray;
}
.form-radio.radio .form-radio__input:checked + .form-radio__label::before {
  background-color: rgb(var(--color-black));
  box-shadow: inset 0 0 0 3px rgb(var(--color-white));
}
.form-radio.radio .form-radio__input:checked + .form-radio__label.disabled::before {
  background-color: rgb(var(--color-mid-gray));
  box-shadow: inset 0 0 0 3px rgb(var(--color-white));
}

.form-radio.graphic-radio {
  @apply relative;
}
.form-radio.graphic-radio .form-radio__label {
  @apply border border-mid-gray p-3 pb-6 rounded-lg transform transition duration-300 flex-col items-center w-32;
}
.form-radio.graphic-radio .form-radio__label:not(.disabled):hover {
  @apply shadow-md border-black;
}
.form-radio.graphic-radio .form-radio__label.is-invalid {
  @apply border-danger-default;
}
.form-radio.graphic-radio .form-radio__input:checked + .form-radio__label {
  @apply shadow-md border-black;
}
.form-radio.graphic-radio .graphic-radio__image {
  @apply mb-3 bg-center bg-no-repeat bg-cover max-w-14;
}

.form-radio .form-radio__label.disabled {
  @apply cursor-default;
}
