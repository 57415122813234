.alert {
  @apply border rounded-lg p-6
}

.alert.alert-secondary {
  @apply border-mid-gray bg-mid-light-gray text-black
}

.alert.alert-danger {
  @apply border-danger-default bg-danger-light text-danger-default
}

.alert.alert-info {
  @apply text-info-default border-info-light bg-info-lighter
}

.alert.alert-success {
  @apply border-success-default bg-success-light text-success-default
}
