.rbc-toolbar button {
  @apply border-dark-gray;
}

.rbc-btn-group {
  @apply w-full
}

.rbc-toolbar-label {
  @apply text-secondary-title font-serif mt-4;
}

.rbc-row.rbc-time-header-cell {
  @apply bg-mid-light-gray;
}

.rbc-header {
  @apply px-3 py-2.5 text-accent border-b-0
}

.rbc-header.rbc-today {
  @apply bg-mid-light-gray;
}

.rbc-allday-cell {
  @apply hidden;
}

.rbc-time-content {
  @apply border-t border-mid-dark-gray
}

.rbc-time-view {
  @apply border-mid-dark-gray rounded
}

.rbc-time-header-content {
  @apply border-l-mid-dark-gray
}

.rbc-header.rbc-header {
  @apply border-l-mid-dark-gray
}

.rbc-time-content > * + * > * {
 @apply border-l-mid-dark-gray
}

.rbc-time-slot:nth-child(2n) {
  @apply border-t-mid-gray border-dashed
}

.rbc-timeslot-group:last-child {
  @apply border-b-0
}

.rbc-timeslot-group {
 @apply border-mid-dark-gray
}

.rbc-day-slot.rbc-time-column.rbc-timeslot-group:last-child {
  @apply border-b-0
}

.rbc-row.rbc-time-header-cell {
  @apply rounded-tr
}

.rbc-time-gutter.rbc-time-column >.rbc-timeslot-group {
  @apply pt-1 px-3
}

.rbc-day-slot.rbc-time-column > div:last-of-type.rbc-timeslot-group {
  @apply border-b-0 /* not working */
}
