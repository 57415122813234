@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');

:root {
  --color-primary: 12 66 93; /* #0C425D */
  --color-secondary: 255 255 255; /* #FFFFFF */
  --color-black: 29 29 29; /* #1D1D1D */
  --color-white: 255 255 255; /* #FFFFFF */
  --color-darkest-gray: 51 51 51; /* #333333 */
  --color-dark-gray: 119 119 119; /* #777777 */
  --color-mid-dark-gray: 190 190 190; /* #BEBEBE */
  --color-mid-gray: 222 222 222; /* #DEDEDE */
  --color-mid-light-gray: 239 239 239; /* #EFEFEF */
  --color-light-gray: 243 243 243; /* #F3F3F3 */
  --color-danger-default: 164 10 0; /* #A40A00 */
  --color-danger-lighter: 252 237 230; /* #FCEDE6 */
  --color-danger-light: 245 198 203; /* #F5C6CB */
  --color-warning-default: 129 98 12; /* #81620C */
  --color-success-darker: 33 110 72; /* #216E48 */
}

* {
  @apply antialiased;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 100px;
}
::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}

.bg-primary::-webkit-scrollbar-thumb,
.bg-primary *::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
}

html,
body {
  @apply h-full;
}
body {
  @apply scroll-smooth font-sans text-black bg-light-gray;
}

h1 {
  @apply text-h1 font-sans;
}
h2 {
  @apply text-h2 font-sans;
}
h3 {
  @apply text-h3 font-sans;
}
h4 {
  @apply text-h4 font-serif;
}
h5 {
  @apply text-h5 font-sans;
}

a:not([href]),a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
