.btn {
  @apply inline-block py-9px px-18px rounded-full border border-transparent cursor-pointer text-nowrap text-center;
}
.btn:hover {
  @apply text-primary;
}

.btn-sm {
  @apply text-sm py-[3px] px-4;
}

.btn-primary,
.btn-default {
  @apply text-secondary bg-primary border-primary;
}
.btn-primary:hover,
.btn-default:hover {
  @apply text-secondary bg-opacity-80 border-opacity-80;
}

.btn-secondary {
  @apply bg-transparent border-primary text-black;
}
.btn-secondary:hover{
  @apply text-secondary bg-primary bg-opacity-25 border-opacity-25;
}
.btn-danger {
  @apply text-white bg-danger-default;
}
.btn-danger:hover {
  @apply text-white bg-opacity-80;
}
.btn-black {
  @apply border-black text-black;
}
.btn.disabled,
.btn:disabled,
.btn-disabled {
  @apply text-black bg-mid-gray border-mid-gray cursor-not-allowed pointer-events-none;
}
.btn.disabled:hover,
.btn:disabled:hover,
.btn-disabled:hover {
  @apply text-black bg-mid-gray border-mid-gray cursor-not-allowed;
}

.btn-forward::after {
  @apply content-btn-forward inline-block flex-none w-[12px] h-[20px];
}
.btn-backward::before {
  @apply content-btn-backward inline-block flex-none w-[12px] h-[20px];
}
