[type="text"]:focus {
  box-shadow: none !important;
}

.form-group {
  @apply flex flex-col gap-4px;
}

[type="search"].form-control,
[type="email"].form-control,
[type="password"].form-control,
[type="text"].form-control,
[type="number"].form-control,
[type="email"].form-control,
[type="password"].form-control,
[type="file"].fileupload,
[type="date"].form-control,
[type="textarea"].form-control,
textarea.form-control,
select.form-control {
  @apply w-full border rounded-4px border-mid-gray bg-white px-2 py-3 text-black placeholder:text-opacity-75 active:border-black focus:border-black focus:outline-0 focus:shadow-none outline-0 disabled:bg-mid-light-gray disabled:text-dark-gray;
}
[type="number"].form-control:focus,
select.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

[type="file"].fileupload {
  @apply p-0 file:cursor-pointer cursor-pointer file:border-0 file:border-r file:border-mid-gray file:border-opacity-25 file:py-3 file:px-4 file:mr-4 file:bg-light-gray file:text-opacity-75;
}

[type="email"].is-invalid,
[type="password"].is-invalid,
[type="file"].is-invalid,
[type="text"].is-invalid,
[type="date"].is-invalid,
select.is-invalid {
  @apply border border-danger-default bg-chablis;
}
.has-error [type="date"].flatpickr-input {
  @apply border border-danger-default bg-chablis;
}
